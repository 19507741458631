import React from "react"
import ContactBackground from "../components/ContactBackground"
import Seo from "../components/Seo"

const Contact = () => {
  return (
    <>
      <Seo title="Обратная связь" />
      <section className="contact-page">
        <ContactBackground />
      </section>
    </>
  )
}

export default Contact
