import { useForm } from "@formspree/react"
import { navigate } from "gatsby"
import React from "react"
const ContactForm = () => {
  const [state, handleSubmit] = useForm("mgedraky")
  if (state.succeeded) {
    return (
      <button onClick={setTimeout(navigate(-1), 5000)}>
        <h4 className="message-sent">
          Сообщение отправлено!
          <br />
          Ответ пришлю вам на почту.
        </h4>
      </button>
    )
  }
  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <input
          type="text"
          name="name"
          placeholder="Как к вам обращаться?"
          className="form-control"
        />
        <input
          type="email"
          name="email"
          placeholder="Электронная почта"
          className="form-control"
        />
        <textarea
          name="message"
          placeholder="Ваше сообщение"
          rows="5"
          className="form-control"
        ></textarea>
        <button
          type="submit"
          className="submit-btn"
          disabled={state.submitting}
        >
          Отправить
        </button>
      </div>
    </form>
  )
}

export default ContactForm
