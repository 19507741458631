import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import BackgroundImage from "gatsby-background-image"
import styled from "styled-components"
import media from "styled-media-query"
import ContactForm from "./ContactForm"

const BackgroundImg = ({ className }) => {
  const {
    allFile: { nodes: data },
  } = useStaticQuery(
    graphql`
      {
        allFile(filter: { name: { eq: "background" } }) {
          nodes {
            id
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
            absolutePath
            name
          }
        }
      }
    `
  )
  const imageStack = [
    data[0].childImageSharp.fluid,
    "linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4))",
  ].reverse()

  return (
    <BackgroundImage fluid={imageStack} className={className}>
      <article className="contact-form">
        <h4>Обратная связь</h4>
        <ContactForm />
      </article>
    </BackgroundImage>
  )
}

const ContactBackground = styled(BackgroundImg)`
  margin: 0 auto;
  width: 70vw;
  height: 410px;
  background-color: transparent;
  position: relative;

  ${media.lessThan("700px")`
  width: 100vw;
  /* background-size: contain;
  background-attachment: scroll; */
  `}
`

export default ContactBackground
